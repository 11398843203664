<ion-row *ngIf="chapters?.length > 1">
  <ion-col size="auto">
    <ion-button fill="clear" shape="round" (click)="previousChapter()" [disabled]="currentChapter?.number === '1'" color="light">
      <ion-icon slot="icon-only" name="left"></ion-icon>
    </ion-button>
  </ion-col>
  <ion-col size="auto" *ngFor="let chapterSelector of firstChapterSelectorsToShow">
    <ion-button fill="outline" shape="round" (click)="changeChapterTo(chapterSelector)" *ngIf="currentChapter?.number === chapterSelector.number">{{ chapterSelector.number }}</ion-button>
    <ion-button fill="outline" shape="round" (click)="changeChapterTo(chapterSelector)" *ngIf="currentChapter?.number !== chapterSelector.number" color="light">{{ chapterSelector.number }}</ion-button>
  </ion-col>
  <ion-col *ngIf="middleChapterSelectorsToShow" size="auto" class="ellipses">
    ...
  </ion-col>
  <ion-col size="auto" *ngFor="let chapterSelector of middleChapterSelectorsToShow">
    <ion-button fill="outline" shape="round" (click)="changeChapterTo(chapterSelector)" *ngIf="currentChapter?.number === chapterSelector.number">{{ chapterSelector.number }}</ion-button>
    <ion-button fill="outline" shape="round" (click)="changeChapterTo(chapterSelector)" *ngIf="currentChapter?.number !== chapterSelector.number" color="light">{{ chapterSelector.number }}</ion-button>
  </ion-col>
  <ion-col *ngIf="lastChapterSelectorsToShow" size="auto" class="ellipses">
    ...
  </ion-col>
  <ion-col size="auto" *ngFor="let chapterSelector of lastChapterSelectorsToShow">
    <ion-button fill="outline" shape="round" (click)="changeChapterTo(chapterSelector)" *ngIf="currentChapter?.number === chapterSelector.number">{{ chapterSelector.number }}</ion-button>
    <ion-button fill="outline" shape="round" (click)="changeChapterTo(chapterSelector)" *ngIf="currentChapter?.number !== chapterSelector.number" color="light">{{ chapterSelector.number }}</ion-button>
  </ion-col>
  <ion-col size="auto">
    <ion-button fill="clear" shape="round" (click)="nextChapter()" [disabled]="currentChapter?.number === chapters.length.toString()" color="light">
      <ion-icon slot="icon-only" name="right"></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>