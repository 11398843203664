import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PreacherPalApiService, Sermon, Request, SermonRequest, SaveSermonTitleRequest, SaveSermonIdeaBoardTextRequest, SermonResponse, SaveSermonEditorTextRequest, BibleVersion, BibleChapterRequest, BibleChapterResponse, BibleBookResponse, BibleBookRequest, BibleVersionRequest, BibleVersionResponse, BibleSearchRequest, BibleSearchResponse, SearchResult, WritersBlock, SaveSermonEditorBlocksRequest, CreateSermonRequest, EditorBlockTemplate, PdfReference, ChatRequest, TemplatePromptRequest } from './preacher-pal-api.service';
import { SermonDisplay } from '../models/sermon-display';
import { MenuController, Platform } from '@ionic/angular';
import { BibleDisplay } from '../models/bible-display';
import { ChatGPTService } from './chat-gpt.service';
import { PdfsDisplay } from '../pages/sermon/pdf-reader/pdf-reader.component';
// import { AuthService } from '../auth/auth.service';
export const CenterContentOptions = {
  BIBLE : "Bible",
  PDFS : "PDFs",
  IDEA_BOARD : "Idea Board",
  EDITOR : "Editor"
}

export const RightSideContentOptions = {
  RESEARCH_HUB : "Research Hub",
  BIBLE : "Bible",
  IDEA_BOARD : "Idea Board"
}



export class SermonControls {
  currentCenterAreaContent: string = CenterContentOptions.EDITOR;
  currentRightSideContent: string = RightSideContentOptions.RESEARCH_HUB;
  collapseLeftSideMenu: boolean = false;
  menuColumnSizeOutOf12: number = 1;
  leftResizableContent: number = 60;
  rightResizableContent: number = 40;

}

@Injectable({
  providedIn: 'root'
})
export class SermonsService {
  currentSermon: SermonDisplay;
  currentSermonSubject: BehaviorSubject<SermonDisplay> = new BehaviorSubject<SermonDisplay>(null);
  currentSermons: SermonDisplay[] = [];
  currentSermonsSubject: BehaviorSubject<SermonDisplay[]> = new BehaviorSubject<SermonDisplay[]>(null);

  editorBlockTemplates: EditorBlockTemplate[] = [];
  editorBlockTemplatesSubject: BehaviorSubject<EditorBlockTemplate[]> = new BehaviorSubject<EditorBlockTemplate[]>(null);

  pdfsDisplay: PdfsDisplay = new PdfsDisplay();
  pdfDisplaySubject: BehaviorSubject<PdfsDisplay> = new BehaviorSubject<PdfsDisplay>(null);

  bibleDisplay: BibleDisplay = new BibleDisplay();
  bibleDisplaySubject: BehaviorSubject<BibleDisplay> = new BehaviorSubject<BibleDisplay>(null);
  bibleSearchResultsSubject: BehaviorSubject<SearchResult[]> = new BehaviorSubject<SearchResult[]>(null);
  sermonControls: SermonControls = new SermonControls();
  sermonControlsSubject: BehaviorSubject<SermonControls> = new BehaviorSubject<SermonControls>(null);

  
  constructor(private _preacherPalApiService: PreacherPalApiService, private _chatGptService: ChatGPTService) {
    
  }

  // openResearchHub() {
  //   this.sermonControls.isResearchHubOpen = true;
    
  //   this._menuCtrl.enable(true, 'end');
  //   this._menuCtrl.open('end');

  //   this.sermonControlsSubject.next(this.sermonControls);
  // }

  // closeResearchHub() {
  //   this.sermonControls.isResearchHubOpen = false;
    
  //   this._menuCtrl.enable(false, 'end');
  // }

  // toggleResearchHub() {
  //   if(this.sermonControls.isResearchHubOpen === true) {
  //     this.closeResearchHub();
  //   } else {
  //     this.openResearchHub();
  //   }
  // }
 

  // toggleResearchHub() {
  //   this.sermonControls.isResearchHubOpen = !this.sermonControls.isResearchHubOpen;
    
  //   this._menuCtrl.enable(this.sermonControls.isResearchHubOpen, 'end');
  //   if(this.sermonControls.isResearchHubOpen) {
  //     this._menuCtrl.open('end')
  //   }

  //   // this._menuCtrl.enable(true, 'end');
  //   // this._menuCtrl.open('end')
  //   this.sermonControlsSubject.next(this.sermonControls);
  // }

  // toggleLeftSideMenu() {
  //   if(this.sermonControls.isLeftSideMenuOpen === true) {
  //     this.closeLeftSideMenu();
  //   } else {
  //     this.openLeftSideMenu();
  //   }
  // }
  // openLeftSideMenu() {
  //   this.sermonControls.isLeftSideMenuOpen = true;

  //   this._menuCtrl.enable(true, 'start');
  //   this._menuCtrl.open('start');

  //   this.sermonControlsSubject.next(this.sermonControls);
  // }

  // closeLeftSideMenu() {
  //   this.sermonControls.isLeftSideMenuOpen = false;
  //   this._menuCtrl.enable(false, 'start');

  //   this.sermonControlsSubject.next(this.sermonControls);
  // }
  // toggleLeftSidetMenu() {
  //   this.sermonControls.isLeftSideMenuOpen = !this.sermonControls.isLeftSideMenuOpen;
  //   this._menuCtrl.enable(this.sermonControls.isLeftSideMenuOpen, 'start');
  //   if(this.sermonControls.isLeftSideMenuOpen) {
  //     this._menuCtrl.open('start')
  //   }
  //   this.sermonControlsSubject.next(this.sermonControls);
  // }
 

  showBible() {
    this.sermonControls.currentCenterAreaContent = CenterContentOptions.BIBLE;
    this.sermonControlsSubject.next(this.sermonControls);
  }

  showPdfs() {
    this.sermonControls.currentCenterAreaContent = CenterContentOptions.PDFS;
    this.sermonControlsSubject.next(this.sermonControls);
  }

  showIdeaBoard() {
    this.sermonControls.currentCenterAreaContent = CenterContentOptions.IDEA_BOARD;
    this.sermonControlsSubject.next(this.sermonControls);
  }

  showEditor() {
    this.sermonControls.currentCenterAreaContent = CenterContentOptions.EDITOR;
    this.sermonControlsSubject.next(this.sermonControls);
  }

  saveIdeaBoardText(sermonId: string, ideaBoardText: string) {
    var request = new SaveSermonIdeaBoardTextRequest();
    request.sermonId = sermonId;
    request.ideaBoardText = ideaBoardText;
    var promise = new Promise<void>((resolve, reject) => {
      this._preacherPalApiService.saveSermonIdeaBoardText(request).subscribe((response: SermonResponse) => {
        if(response.isSuccess) {
          resolve();
        }
      });
    });

    return promise;

  }

  saveSermonEditorText(sermonId: string, editorText: string) {
    var request = new SaveSermonEditorTextRequest();
    request.sermonId = sermonId;
    request.editorText = editorText;
    var promise = new Promise<void>((resolve, reject) => {
      this._preacherPalApiService.saveSermonEditorText(request).subscribe((response: SermonResponse) => {
        if(response.isSuccess) {
          resolve();
        }
      });
    });

    return promise;

  }

  saveSermonEditorBlocks(sermonId: string, editorBlocks: WritersBlock[]) {
    var request = new SaveSermonEditorBlocksRequest();
    request.sermonId = sermonId;
    request.editorBlocks = editorBlocks;

    var promise = new Promise<void>((resolve, reject) => {
      this._preacherPalApiService.saveSermonEditorBlocks(request).subscribe((response: SermonResponse) => {
        if(response.isSuccess) {
          resolve();
        }
      });
    });

    return promise;

  }

  createSermon(title: string, sermonTemplateId: string) {
    
    
    const request = new CreateSermonRequest({ title: title, templateId: sermonTemplateId });
    const promise = new Promise<Sermon>((resolve, reqject) => {
      this._preacherPalApiService.createSermon(request).subscribe((response: SermonResponse) => {
        if(response.isSuccess) {
          response.sermon.title = title;
          this.setCurrentSermon(response);
          this.currentSermons.push(this.currentSermon);
          this.currentSermonsSubject.next(this.currentSermons);


          this._chatGptService.sendTemplatePromptToChatGpt(response.sermon.id, sermonTemplateId);
          resolve(response.sermon);
        }
      });
    });
    return promise;
  }

  

  

  loadCurrentSermon(sermonId: string) {
    if(sermonId && this.currentSermon?.id != sermonId) {
      var request = new SermonRequest();
      request.sermonId = sermonId;
      this._preacherPalApiService.getSermonById(request).subscribe((response: SermonResponse) => {
        if(response.isSuccess) {
          this.setCurrentSermon(response);
          this.showEditor();
          this.resizeSplitAreas();
        }
      });
    }  else {
      this.showEditor();
      this.resizeSplitAreas();
    }
  }

  private setCurrentSermon(response: SermonResponse) {
    this.currentSermon = new SermonDisplay(response.sermon);
    this.currentSermonSubject.next(this.currentSermon);

    if(response.bibleVersions && response.books && response.defaultBibleChapter) {
      this.bibleDisplay.englishBibleVersions = response.bibleVersions?.filter(x => x.languageCode_Iso639_3 === "eng");
      this.bibleDisplay.spanishBibleVersions = response.bibleVersions?.filter(x => x.languageCode_Iso639_3 === "spa");
      var selectedVersion = this.bibleDisplay.englishBibleVersions?.find(x => x.id === response.defaultBibleChapter.bibleId);
      if(!selectedVersion) {
        selectedVersion = this.bibleDisplay.spanishBibleVersions?.find(x => x.id === response.defaultBibleChapter.bibleId);
      }
      this.bibleDisplay.selectedVersion = selectedVersion
      this.bibleDisplay.books = response.books;
      this.bibleDisplay.selectedBook = this.bibleDisplay.books?.find(x => x.id === response.defaultBibleChapter.bookId);
      this.bibleDisplay.selectedChapter = response.defaultBibleChapter;
    } else {
      this.bibleDisplay.isServiceUnavailableError = true;
    }
    
    this.bibleDisplaySubject.next(this.bibleDisplay);
  }


  cascadeDeleteSermonById(sermonId) {
    var request = new SermonRequest();
    request.sermonId = sermonId;
    const promise = new Promise<void>((resolve, reqject) => {
      this._preacherPalApiService.cascadeDeleteSermon(request).subscribe((response) => {
        if(response.isSuccess) {
          this.currentSermons = this.currentSermons.filter(x => x.id !== sermonId);
          this.currentSermonsSubject.next(this.currentSermons);
          this.currentSermon = null;
          this.currentSermonSubject.next(null);
          //this.closeResearchHub();
          resolve();
        }
      });
    });
    return promise;
  }
 
  
  setSermonTitle(sermon: Sermon) {
    var request = new SaveSermonTitleRequest();
    request.sermonId = sermon.id;
    request.title = sermon.title;
    const promise = new Promise<void>((resolve, reqject) => {
      this._preacherPalApiService.saveSermonTitle(request).subscribe((response) => {
        if(response.isSuccess) {
          this.currentSermons.forEach((x: Sermon) => {
            if(x.id === sermon.id) {
              x.title = sermon.title;
            }
          });
          this.currentSermonsSubject.next(this.currentSermons);
          resolve();
        }
      });
    });
    return promise;
  }

  setSermons(sermons: SermonDisplay[]) {
    if(sermons) {
      this.currentSermons = sermons;
      this.currentSermonsSubject.next(this.currentSermons);
    }
    
  }

  setEditorBlockTemplates(editorBlockTemplates: EditorBlockTemplate[]) {
    if(editorBlockTemplates) {
      this.editorBlockTemplates = editorBlockTemplates;
      this.editorBlockTemplatesSubject.next(this.editorBlockTemplates);
    }
  }

  

  loadBibleBookChapter(bibleId: string, chapterId: string) {
    var request = new BibleChapterRequest();
    request.sermonId = this.currentSermon.id;
    request.bibleId = bibleId;
    request.chapterId = chapterId;
    var promise = new Promise<void>((resolve, reject) => {
      this._preacherPalApiService.getBibleBookChapter(request).subscribe((response: BibleChapterResponse) => {
        if(response.isSuccess) {
          this.bibleDisplay.selectedChapter = response.chapter;
          // this.bibleDisplay.books.forEach(x => {
          //   x.chapters = x.chapters.filter(y => y.number != 'intro');
          // });
        } else {
          this.bibleDisplay.isServiceUnavailableError = true;
        }
        this.bibleDisplaySubject.next(this.bibleDisplay);
      });
    });

    return promise;

  }

  loadBibleBook(bibleId: string, bookId: string) {
    var request = new BibleBookRequest();
    request.sermonId = this.currentSermon.id;
    request.bibleId = bibleId;
    request.bookId = bookId;
    var promise = new Promise<void>((resolve, reject) => {
      this._preacherPalApiService.getBibleBook(request).subscribe((response: BibleBookResponse) => {
        if(response.isSuccess) {
          this.bibleDisplay.selectedBook = response.book;
          this.bibleDisplay.selectedChapter = response.chapter;
          // this.bibleDisplay.books.forEach(x => {
          //   x.chapters = x.chapters.filter(y => y.number != 'intro');
          // });
          
        } else {
          this.bibleDisplay.isServiceUnavailableError = true;
        }
        this.bibleDisplaySubject.next(this.bibleDisplay);
      });
    });

    return promise;

  }

  loadBibleVersion(bibleId: string) {
    var request = new BibleVersionRequest();
    request.sermonId = this.currentSermon.id;
    request.bibleId = bibleId;
    var promise = new Promise<void>((resolve, reject) => {
      this._preacherPalApiService.getBibleVersion(request).subscribe((response: BibleVersionResponse ) => {
        if(response.isSuccess) {
          this.bibleDisplay.books = response.books;
          this.bibleDisplay.selectedBook = this.bibleDisplay.books.find(x => x.id === response.chapter.bookId);
          this.bibleDisplay.selectedChapter = response.chapter;
          // this.bibleDisplay.books.forEach(x => {
          //   x.chapters = x.chapters.filter(y => y.number != 'intro');
          // });
        } else {
          this.bibleDisplay.isServiceUnavailableError = true;
        }
        this.bibleDisplaySubject.next(this.bibleDisplay);
      });
    });

    return promise;

  }

  loadBibleSearchResults(searchText: string) {
    this.bibleDisplay.selectedBook = null;
    this.bibleDisplay.selectedChapter = null;
    var request = new BibleSearchRequest();
    request.sermonId = this.currentSermon.id;
    request.bibleId = this.bibleDisplay.selectedVersion.id;
    request.searchText = searchText;
      this._preacherPalApiService.searchBible(request).subscribe((response: BibleSearchResponse) => {
        if(response.isSuccess) {
          if(response.passages) {
            this.bibleSearchResultsSubject.next(response.passages);
          } else if(response.verses) {
            this.bibleSearchResultsSubject.next(response.verses);
          }
          
          

          // this.bibleDisplay.books = response.books;
          // this.bibleDisplay.selectedBook = this.bibleDisplay.books.find(x => x.id === response.chapter.bookId);
          // this.bibleDisplay.selectedChapter = response.chapter;
          // this.bibleDisplaySubject.next(this.bibleDisplay);
        }
      });
    

  }

  setPdfsDisplay(pdfReferences: PdfReference[]) {
    if(pdfReferences) {
      this.pdfsDisplay.pdfReferences = pdfReferences;
      this.pdfsDisplay.selectedPdf = pdfReferences[0];
      this.pdfDisplaySubject.next(this.pdfsDisplay);
    }
  }

  loadPdfPage(pdfId: string, pdfPageNumber: number) {
    //todo use id instead?
    this.pdfsDisplay.selectedPdf = this.pdfsDisplay.pdfReferences.find(x => x.id === pdfId);
    this.pdfsDisplay.selectedPdfPageNumber = pdfPageNumber;
    this.pdfDisplaySubject.next(this.pdfsDisplay);
  }
 
  resizeSplitAreas() {
    this.sermonControls.leftResizableContent = 60;
    this.sermonControls.rightResizableContent = 40;
  }

  toggleLeftSideExpansion() {
    this.sermonControls.collapseLeftSideMenu = !this.sermonControls.collapseLeftSideMenu;
  }

}
