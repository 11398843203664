import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountsService } from 'src/app/services/accounts.service';
import { UserDisplay } from '../models/user-display';
@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  private _currentUser: UserDisplay;
  constructor(private router: Router, private _accountsService: AccountsService) {
  }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        let redirect = route.data["redirect"] as string;
        const promise = new Promise<boolean | UrlTree>((resolve, reject) => {
          if(this._currentUser?.isAdmin) {
            resolve(true);
          } else {
            this._accountsService.currentUserSubject.subscribe((currentUser: UserDisplay) => {
              if(currentUser?.isFullyLoaded ) {
                this._currentUser = currentUser
                if(this._currentUser.isAdmin) {
                  resolve(true);
                } else {
                  resolve(this.router.parseUrl(redirect));
                }
              }
            });
          }
        });
        

        return promise;
    }
}
