<ion-list lines="none">
  <ion-item (click)="copyToClipboard()">
    <ion-icon name="copy"></ion-icon>Copy
  </ion-item>
  <ion-item *ngIf="!isSingleWord" (click)="showSimilarVerses()">
    <ion-icon name="bible"></ion-icon>Similar verses found in the Bible
  </ion-item>
  <ion-item (click)="showTranslations()">
    <ion-icon name="translate"></ion-icon>Show translations
  </ion-item>
  <ion-item (click)="getIllustration()">
    <ion-icon name="pen"></ion-icon>Create an illustration
  </ion-item>
  <!-- <ion-item>
    <ion-label>
      Copy to Idea Board
    </ion-label>
  </ion-item> -->
  <ion-item (click)="getHistoricalReference()">
    <ion-icon name="hourglass-outline"></ion-icon>What is the historical context of this?
  </ion-item>
  <!-- <ion-item>
    <ion-label>
      What are some good commentaries on this verse?
    </ion-label>
  </ion-item> -->
  <ion-item *ngIf="!isSingleWord" (click)="getBiblicalEtymology()">
    <ion-icon name="notebook"></ion-icon>What is the etymology of this phrase?
  </ion-item>
  <ion-item *ngIf="isSingleWord" (click)="getBiblicalEtymology()">
    <ion-icon name="notebook"></ion-icon>What is the etymology of this word?
  </ion-item>
</ion-list>