<ion-card [ngClass]="[block.tagColor]">
  <div class="title">
   
      <ion-input class="item-has-focus" [ngClass]="[block.tagColor]" (keyup.enter)="saveTitleText()" autofocus="true" (ionChange)="saveTitleText()" [(ngModel)]="block.title"  placeholder="Title">
      </ion-input>
      <ion-buttons class="card-buttons">
        <ion-button *ngIf="block.isExpanded" (click)="block.isExpanded = !block.isExpanded;" class="expansion-button" fill="clear" slot="end" aria-label="Show/hide">
          <!-- <ion-icon *ngIf="!block.isExpanded" slot="icon-only" name="up" aria-hidden="true"></ion-icon> -->
          <ion-icon  slot="icon-only" name="down" aria-hidden="true"></ion-icon>
        </ion-button>
        <ion-button (click)="delete()" fill="clear" slot="end" aria-label="Show/hide">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
        <!-- <span *ngIf="showDeleteDiaglog">
          <span>Delete this Block?</span><span (click)="delete()">yes</span> <span (click)="showDeleteDiaglog = false">no</span>
        </span> -->
        
        
      </ion-buttons>
      
      
  </div>
  
  <ion-card-content class="collapsed" (click)="switchToEditableEditor()" *ngIf="!block.isExpanded">
    <quill-editor style="display: block;" readOnly="true" [modules]="{ toolbar: false }" [(ngModel)]="block.body"></quill-editor>
  </ion-card-content>

  <app-expandable [expanded]="block.isExpanded">
    <ion-card-content>
      <ion-row>
        <ion-col>
          <quill-editor [autofocus]="true" (onEditorCreated)="onEditorCreated($event)" (onEditorChanged)="saveBodyText()" [(ngModel)]="block.body"></quill-editor>
        </ion-col>
      </ion-row>
      
      <ion-row>
        <ion-col class="tags vertical-center-in-col">
          <p>Tag: </p><div (click)="block.tagColor = tagColors.BLUE" class="tag-color blue" [ngClass]="{ 'active':  block.tagColor === tagColors.BLUE }"></div> 
               <div (click)="saveTagColor(tagColors.PURPLE)" class="tag-color purple" [ngClass]="{ 'active':  block.tagColor === tagColors.PURPLE }"></div>
               <div (click)="saveTagColor(tagColors.YELLOW)" class="tag-color yellow" [ngClass]="{ 'active':  block.tagColor === tagColors.YELLOW }"></div>
               <div (click)="saveTagColor(tagColors.RED)" class="tag-color red" [ngClass]="{ 'active':  block.tagColor === tagColors.RED }"></div> 
               <div (click)="saveTagColor(tagColors.GREEN)" class="tag-color green" [ngClass]="{ 'active':  block.tagColor === tagColors.GREEN }"></div>
               <div (click)="saveTagColor(tagColors.GREY)" class="tag-color grey" [ngClass]="{ 'active':  block.tagColor === tagColors.GREY }"></div>
           
        </ion-col>
      </ion-row>
    </ion-card-content>
  </app-expandable>
</ion-card>