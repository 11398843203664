<ion-app>


  <app-pal-header></app-pal-header>

  <ion-split-pane contentId="main-content">
    
    <!-- <ion-menu class="start-menu" side="start" menuId="start" contentId="main-content" type="overlay">
      <app-left-side-menu></app-left-side-menu>
    </ion-menu> -->
    

    <ion-router-outlet id="main-content"></ion-router-outlet>

    <!-- <ion-menu class="end-menu" side="end" contentId="main-content" type="overlay">
      <app-research-hub></app-research-hub>
    </ion-menu> -->
  </ion-split-pane>
</ion-app>
