import { Injectable } from '@angular/core';
import { SubscriptionPackage } from '../models/subscription-package';
import { CreatePromotionalDiscountRequest, PreacherPalApiService, PromoCodeReqeust, PromotionalDiscount, PromotionalDiscountResponse, StripeCheckoutRequest, StripeCheckoutResponse } from './preacher-pal-api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  

  constructor(private _preacherPalApiService: PreacherPalApiService, private _router: Router) { }

  createStripeCheckout(subscriptionPackage: SubscriptionPackage) {

    var request = new StripeCheckoutRequest();
    request.planTier = subscriptionPackage.planTier;
    request.stripePriceId = subscriptionPackage.stripePriceId;
    request.promoCode = subscriptionPackage.promoCode;
    this._preacherPalApiService.createStripeCheckout(request).subscribe((response: StripeCheckoutResponse) => {
      if(response.isSuccess) {
        if(response.isStripeBypassFreeForever) {
          this._router.navigateByUrl('/home');
        } else if(response.stripeSessionUrl) {
          window.open(response.stripeSessionUrl, "_self")
        }
        
      }
    });
  }

  createEarlyAdopter25PercentPromotionalDiscount(message: string = null) {
    const request = new CreatePromotionalDiscountRequest();
    request.message = message;
    
    const promise = new Promise<PromotionalDiscount>((resolve, reject) => {
      this._preacherPalApiService.createEarlyAdopter25PercentPromotionalDiscount(request).subscribe((response: PromotionalDiscountResponse) => {
          if(response.isSuccess) {
            resolve(response.promotionalDiscount)
          }
        });
    });

    return promise; 
  }

  createEarlyAdopterMonthlyPromotionalDiscount(message: string = null) {
    const request = new CreatePromotionalDiscountRequest();
    request.message = message;
    
    const promise = new Promise<PromotionalDiscount>((resolve, reject) => {
      this._preacherPalApiService.createEarlyAdopterMonthlyPromotionalDiscount(request).subscribe((response: PromotionalDiscountResponse) => {
          if(response.isSuccess) {
            resolve(response.promotionalDiscount)
          }
          
        });
    });

    return promise; 
  }

  createEarlyAdopterYearlyPromotionalDiscount(message: string = null) {
    const request = new CreatePromotionalDiscountRequest();
    request.message = message;
    
    const promise = new Promise<PromotionalDiscount>((resolve, reject) => {
      this._preacherPalApiService.createEarlyAdopterYearlyPromotionalDiscount(request).subscribe((response: PromotionalDiscountResponse) => {
          if(response.isSuccess) {
            resolve(response.promotionalDiscount)
          }
          
        });
    });

    return promise; 
  }

  applyPromoCode(promoCode: string) {
    const request = new PromoCodeReqeust();
    request.promoCode = promoCode;
    
    const promise = new Promise<PromotionalDiscountResponse>((resolve, reject) => {
      this._preacherPalApiService.applyPromoCode(request).subscribe((response: PromotionalDiscountResponse) => {
          resolve(response)
        });
    });

    return promise;
  }
}
