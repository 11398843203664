import { BibleVersion, Sermon } from "../services/preacher-pal-api.service";
import { ChatMessageDisplay } from "./chat-message-display";

export class SermonDisplay extends Sermon {
    constructor(sermon: Sermon) {
        super(sermon);
        const newLocal = this;
        newLocal.chatMessageDisplays = this.chatMessages?.map(x => new ChatMessageDisplay(x));
        if(!this.editorBlocks) {
            this.editorBlocks = [];
        }
        if(!this.ideaBoardBlocks) {
            this.ideaBoardBlocks = [];
        }
    }
    
    
    tagURL: string =  "https://storage.googleapis.com/preacherpal.us/tags/tag-blue.png";
    chatMessageDisplays: ChatMessageDisplay[];
    bibleVersions: BibleVersion[];
}