import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';
import { AppRoutingModule } from './app-routing.module';

import { TokenInterceptorService } from './token-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { AuthConfig } from './auth/auth.config';
import { AuthFlowPageModule } from './auth/auth-flow/auth-flow.module';
import { AuthModule } from './auth/auth.module';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { getApp } from 'firebase/app';
import { initializeAuth, indexedDBLocalPersistence } from 'firebase/auth';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { API_BASE_URL, PreacherPalApiService } from './services/preacher-pal-api.service';
import { FormsModule } from '@angular/forms';
export function getAPIBaseUrl(): string {
  var test = Capacitor.getPlatform();
  var testt = test; 
  return environment.preacherPalApiBaseUrl;
}


@NgModule({
  declarations: [
    AppComponent
    
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ 
      animated: false, 
      platform: {
        'desktop': (win) => {
          const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
          return !isMobile;
        }
      }
    }),
    AppRoutingModule,
    // IonicStorageModule.forRoot(),
    HttpClientModule,
    SharedModule,
    FormsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        });
      } else {
        return getAuth();
      }
    }),
  ],
  providers: [
    Facebook,
    StatusBar,
    PreacherPalApiService,
    Storage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: API_BASE_URL, useFactory: getAPIBaseUrl },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    ScreenTrackingService,UserTrackingService, 
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}


