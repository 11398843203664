import { Injectable } from '@angular/core';
import { ChatGPTMessage, ChatGPTResponse, ChatMessage, ChatRequest, PreacherPalApiService, SermonResponse, TemplatePromptRequest } from './preacher-pal-api.service';
import { BehaviorSubject } from 'rxjs';
import { ChatMessageDisplay } from '../models/chat-message-display';
import { DomSanitizer } from '@angular/platform-browser';
import { resolve } from 'dns';
import { SermonDisplay } from '../models/sermon-display';

@Injectable({
  providedIn: 'root'
})
export class ChatGPTService {

  mostRecentMessageSubject: BehaviorSubject<ChatMessageDisplay> = new BehaviorSubject<ChatMessageDisplay>(null);
  // mostRecentPromptSuggestionsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

  constructor(private _preacherPalApi: PreacherPalApiService, private _sanitizer: DomSanitizer) { 
    // var test = 5;
  }

  sendMessage(message: ChatMessage, temperature: number = 0.5) {
    this.mostRecentMessageSubject.next(new ChatMessageDisplay(message));

    //var promise = new Promise<boolean>((resolve, reject) => {
      var request = new ChatRequest();
      request.message = message.content;
      request.sermonId = message.sermonId;
      request.temperature = temperature;
      this._preacherPalApi.sendMessageToChatGPT(request).subscribe((response: ChatGPTResponse) => {
        if(response?.isSuccess) {
          // resolve(true);
          var chatMessage = new ChatMessage();
          chatMessage.role = response.message.role;
          chatMessage.content = response.message.content;
          chatMessage.sermonId = message.sermonId;
          this.mostRecentMessageSubject.next(new ChatMessageDisplay(chatMessage));
          // this.mostRecentPromptSuggestionsSubject.next(response.promptSuggestions);
          
        }
      });
    //});
    
    //return promise;
  }
  sendTemplatePromptToChatGpt(sermonId: string, templateId: string) {
    const request = new TemplatePromptRequest({ sermonId: sermonId, editorBlocksTemplateId: templateId  });

    this._preacherPalApi.sendTemplatePromptToChatGPT(request).subscribe((response: ChatGPTResponse) => {
      if(response.isSuccess) {
        var chatMessage = new ChatMessage();
        chatMessage.role = response.message.role;
        chatMessage.content = response.message.content;
        chatMessage.sermonId = sermonId;
        this.mostRecentMessageSubject.next(new ChatMessageDisplay(chatMessage));
      }
    });
  }

  setFirstResponse(message: ChatGPTMessage, sermonId: string) {
    var chatMessage = new ChatMessage();
    chatMessage.role = message.role;
    chatMessage.content = message.content;
    chatMessage.sermonId = sermonId;
    this.mostRecentMessageSubject.next(new ChatMessageDisplay(chatMessage));
  }


}
