<ion-content class="jacobi-auth-flow jacobi-auth-flow-reset-pw-init">
<ion-grid>
    <ion-row class="reset-pw-init-outer-row outer-row">
        
        <ion-col
            class="content-col"
            [attr.size-sm]="    (splitScreenBgImage ? 12 : undefined)"
            [attr.size-md]="    (splitScreenBgImage ? 6  : undefined)"
            [attr.offset-md]="  (splitScreenBgImage ? 6  : undefined)"
            [attr.pull-md]="    (splitScreenBgImage ? 3  : undefined)"
            [attr.size-lg]="    (splitScreenBgImage ? 6  : undefined)"
            [attr.offset-lg]="  (splitScreenBgImage ? 0  : undefined)"
            [attr.pull-lg]="    (splitScreenBgImage ? 0  : undefined)"
        >

            <ion-row>
                <ion-col class="interface-col" 
                    size-sm="12"
                    size-md="12"
                    offset-md="2" size-md="8"
                    offset-lg="4" size-lg="4">
                    <ion-img class="logo-with-words" src="https://storage.googleapis.com/preacherpal.us/images/logo-with-words.png"></ion-img>

                    <ion-row class="page-heading">                        
                        <ion-col>
                            <div *ngIf="!(isSuccess || isFailure)">
                                Change Password
                                <p class="sub-heading">
                                    For security reasons, we'll send you a sign in link to
                                    verify your account. Click below to start the
                                    process.
                                  </p>
                            </div>
                            <div *ngIf="isSuccess">
                                Success
                            </div>
                            <div *ngIf="isFailure">
                                Something went wrong
                            </div>
                        </ion-col>
                    </ion-row>

                    <ion-row *ngIf="isSuccess">
                        <ion-col>
                            {{successMessage}}
                        </ion-col>
                    </ion-row>
                    <ion-row *ngIf="isFailure">
                        <ion-col>
                            {{failureMessage}}
                        </ion-col>
                    </ion-row>
        
                    <ion-row class="form-row jacobi-auth-form-row">
                        <ion-col>
                            <form *ngIf="!(isSuccess || isFailure)" (ngSubmit)="sendPasswordResetLink()" [formGroup]="forgotPasswordForm"
                                class="form-reset-pw-init">
                                <ion-row>
                                    <ion-col class=" email-input-wrapper">
                                        <ion-item class="input-item">
                                            <ion-label position="floating">Email</ion-label>
                                            <ion-input [formControlName]="'email'"></ion-input>
                                            <ion-icon class="form-icon" slot="end" name="mail-outline"></ion-icon>
                                          </ion-item>
                                          <div *ngIf="forgotPasswordForm.controls.email.invalid && forgotPasswordForm.controls.email.touched">
                                            
                                            <ion-text color="danger" *ngIf="forgotPasswordForm.controls.email.errors?.['required']">
                                                Email is required.
                                            </ion-text>
                                            <ion-text color="danger" *ngIf="forgotPasswordForm.controls.email.errors?.['email']">
                                              Must be a valid email address. 
                                            </ion-text>
                                          </div>
                                    </ion-col>
                                </ion-row>
        
                                <ion-row class="auth-action-buttons">
                                    <ion-col>
                                        <ion-row>
                                            <ion-col *ngIf="!(isSuccess || isFailure)" class=" reset-btn-wrapper">
                                                <ion-button (click)="(!forgotPasswordForm.invalid ? sendPasswordResetLink() : null)" part="button" [disabled]="forgotPasswordForm.invalid" class="jacobi-button auth-primary">
                                                    Reset Password
                                                </ion-button>
        
                                            </ion-col>
                                            <ion-col *ngIf="isSuccess" class=" sign-in-btn-wrapper">
                                                <ion-button [routerLink]="'sign-in'" part="button" [disabled]="false" class="jacobi-button auth-primary">
                                                    Sign In
                                                </ion-button>
        
                                            </ion-col>
                                            <ion-col *ngIf="isFailure" class=" resend-btn-wrapper">
                                                <p>{{ failureMessage }}</p>
                                                <ion-button (click)="(!forgotPasswordForm.invalid ? sendPasswordResetLink() : null)" part="button" [disabled]="forgotPasswordForm.invalid" class="jacobi-button auth-primary">
                                                    Resend Link
                                                </ion-button>
        
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col class=" cancel-btn-wrapper">
                                                <ion-button (click)="dismiss()"  part="button" class="jacobi-button ghost-auth-secondary">
                                                    Cancel
                                                </ion-button>
                                            </ion-col>
                                        </ion-row>
        
                                        <!-- link to reset email -->
                                        <ion-row class="row-lost-email ion-text-center">
                                            <ion-col>
                                                <p class="">
                                                    <a class="clickable">I don't have access to my Email</a>
                                                </p>
                                            </ion-col>
                                        </ion-row>
                                    </ion-col>
                                </ion-row>
                            </form>
                        </ion-col>
                    </ion-row>

                </ion-col>
            </ion-row>

            
        </ion-col>

        <!-- split-screen -->
        <ion-col *ngIf="splitScreenBgImage"
            size-lg="6"
            class="hide-lg-and-below split-page-banner"
            [ngStyle]="{
                'background-image': 'url('+ splitScreenBgImage +')',
                'background-repeat': 'no-repeat',
                'background-position': 'center center',
                'background-size': 'cover'
            }"
            >
            <div class="img-overlay"></div>
        </ion-col>
    </ion-row>
</ion-grid>
</ion-content>
