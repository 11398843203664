import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PalHeaderComponent } from './pal-header/pal-header.component';
import { BiblePaginationComponent } from './bible-pagination/bible-pagination.component';
import { RightClickOptionsComponent } from '../right-click-options/right-click-options.component';
import { ExpandableComponent } from './expandable/expandable.component';
import { WritersBlockComponent } from './writers-block/writers-block.component';
import { QuillModule } from 'ngx-quill';
import { PromoCodeComponent } from './promo-code/promo-code.component';
import { PaginationComponent } from './pagination/pagination.component';


@NgModule({
  declarations: [
    PalHeaderComponent,
    BiblePaginationComponent,
    PaginationComponent,
    RightClickOptionsComponent,
    ExpandableComponent,
    WritersBlockComponent,
    PromoCodeComponent
  ],
  imports: [
    RouterModule,
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule
  ],
  exports: [
    PalHeaderComponent,
    BiblePaginationComponent,
    PaginationComponent,
    RightClickOptionsComponent,
    ExpandableComponent,
    WritersBlockComponent,
    PromoCodeComponent
  ]
})
export class SharedModule { }
