import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { AccountsService } from '../services/accounts.service';
import { UserDisplay } from '../models/user-display';
import { SubscriptionPlanTypes } from '../models/constants';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private router: Router, private _authService: AuthService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const promise = new Promise<boolean | UrlTree>((resolve, reject) => {
      this._authService.isAuthenticated().then((isAuthenticated) => {
        if (isAuthenticated) {
          resolve(true);
        } else {
          var redirectPath = environment.unauthenticatedRedirect;
          resolve(this.router.parseUrl(redirectPath));
        }
      });
    });
    return promise;
    
  }
}
