import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SubscriptionPackage } from 'src/app/models/subscription-package';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss'],
})
export class PromoCodeComponent  implements OnInit {
  @Output() onPromoCodeClicked = new EventEmitter<void>();  
  @Output() onPromoCodeAccepted = new EventEmitter<SubscriptionPackage>();
  promoCodeMessage: string;
  isPromoCodeApplied: boolean;
  promoCodeWorked: boolean;
  showPromoCodeForm: boolean;
  
  subscriptionPackage: SubscriptionPackage = new SubscriptionPackage();
  public promoCodeForm = new FormGroup({
    promoCode: new FormControl('', [  ])
  });
  constructor(private _subscriptionService: SubscriptionService) { }

  ngOnInit() {}

  cancel() {
    this.isPromoCodeApplied = false;
    this.showPromoCodeForm = false;
    this.promoCodeForm.value.promoCode = "";
    this.onPromoCodeAccepted.emit(null);
  }

  openPromoCodeForm() {
    this.showPromoCodeForm = true;
    this.onPromoCodeClicked.emit();
  }

  async enterPromoCode() {
    this.promoCodeWorked = false;
    var promoCode = this.promoCodeForm.value.promoCode;
    var promomotionalDiscountRepsponse = await this._subscriptionService.applyPromoCode(promoCode);
    if(promomotionalDiscountRepsponse.isSuccess) {
      this.subscriptionPackage.promoCode = promoCode;
      this.promoCodeMessage = promomotionalDiscountRepsponse.promotionalDiscount.message;
      this.promoCodeWorked = true;
    } else {
      this.promoCodeMessage = promomotionalDiscountRepsponse.errors[0]?.errorMessage;
    }

    this.isPromoCodeApplied = true;
    this.showPromoCodeForm = false;
    this.promoCodeForm.value.promoCode = "";
    this.onPromoCodeAccepted.emit(this.subscriptionPackage);
  }

}
