<ion-row *ngIf="!showPromoCodeForm">
  <ion-col *ngIf="!isPromoCodeApplied">
      <p (click)="openPromoCodeForm()" class="ion-text-center clickable ion-no-margin">
         Enter promo code
      </p>
  </ion-col>
  <ion-col *ngIf="isPromoCodeApplied && !promoCodeWorked">
      <p *ngIf="promoCodeMessage && promoCodeMessage !== ''">
          {{ promoCodeMessage }}
      </p>
      <p class="ion-text-center clickable ion-no-margin" (click)="openPromoCodeForm()">
        Try again?
    </p>
  </ion-col>
  <ion-col *ngIf="isPromoCodeApplied && promoCodeWorked">
      <p class="ion-text-center ion-no-margin">
          {{ promoCodeMessage }} Click on Subscribe to get your discount!
      </p>
  </ion-col>
</ion-row>
<ion-row>
  <ion-col>
    <form *ngIf="showPromoCodeForm" (ngSubmit)="enterPromoCode()" [formGroup]="promoCodeForm" class="form">
      <ion-row class="ion-align-items-center">
          <ion-col offset="1.5" size="9">
            <ion-input label="Promo code" labelPlacement="floating" autofocus="true" fill="outline" shape="round" [formControlName]="'promoCode'"></ion-input>
          </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col size="auto" class="ion-justify-content-center ion-text-center">
          <ion-button class="promo-button pal-button" (click)="cancel()">
              Cancel
          </ion-button>
        </ion-col>
        <ion-col size="auto" class="ion-justify-content-center ion-text-center">
          <ion-button class="promo-button pal-button" type="submit">
              Apply Code
          </ion-button>
        </ion-col>
      </ion-row>
    </form>
  </ion-col>
</ion-row>