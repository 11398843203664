import { AfterViewChecked, Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { filter } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { UserDisplay } from 'src/app/models/user-display';
import { AccountsService } from 'src/app/services/accounts.service';
import { SermonsService } from 'src/app/services/sermons.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pal-header',
  templateUrl: './pal-header.component.html',
  styleUrls: ['./pal-header.component.scss'],
})
export class PalHeaderComponent  implements AfterViewChecked {
  pageTitle = "Preacher Pal";
  isDesktop: boolean;
  currentUser: UserDisplay | undefined;
  isStartMenuOpen: boolean = true;
  isEndMenuOpen: boolean = true;
  palHeaderHeight: number = 0;
  showHeader = true;
  @ViewChild('palHeader', { read: ElementRef }) palHeader: ElementRef;
 
  constructor(private _platform: Platform, private _authService: AuthService, private _router: Router,
    private _accountsService: AccountsService, private _sermonsService: SermonsService) { 

      this._router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.showHeader = !event.url.includes('auth-flow');
        
      });
    // this.isDesktop = this._platform.is("desktop");
    // if(!this.isDesktop) {
    //   this.pageTitle = "Preacher Pal"
    // }

    this._accountsService.currentUserSubject.subscribe((currentUser: UserDisplay) => {
        this.currentUser = currentUser;
    });
  }

  async ngAfterViewChecked() {
    
    this.showHeader = !this._router.url.includes('auth-flow');

    if(this.showHeader) {
      var palHeaderHeight = this.palHeader.nativeElement.offsetHeight;
      if(palHeaderHeight !== 0 && this.palHeaderHeight === 0) {
        this.palHeaderHeight = palHeaderHeight;
        document.documentElement.style.setProperty('--current-header-height', `${this.palHeaderHeight}px`);
      } 
    } else {
      this.palHeaderHeight = 0;
      document.documentElement.style.setProperty('--current-header-height', `${this.palHeaderHeight}px`);
    }
    
  }

  // toggleLeftSideMenu() {
  //   this._sermonsService.toggleLeftSideMenu();
  // }
  // toggleResearchHub() {
  //   this._sermonsService.toggleResearchHub();
  // }

  signOut() {
    this._authService.signOut();
    // this._sermonsService.closeLeftSideMenu();
    // this._sermonsService.closeResearchHub();
    var redirectPath = environment.unauthenticatedRedirect;
    this._router.navigateByUrl(redirectPath);
  }

}
