import { environment } from "src/environments/environment";
import { SubscriptionPlanTypes } from "./constants";

export class SubscriptionPackage {
    constructor() {
        // to begin, we are doing grandfaterh only
        this.planTier = SubscriptionPlanTypes.STANDARD;
        this.stripePriceId = environment.stripeSubscription.standardMonthlyPriceId;
    }
    public promoCode: string;
    public stripePriceId: string;
    public planTier: string; 
  
   
  }