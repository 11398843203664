// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  preacherPalApiBaseUrl: 'https://preacher-pal-api-hhlasr2nrq-ue.a.run.app',
  preacherPalUIBaseUrl: 'https://preacher-pal-ui-hhlasr2nrq-ue.a.run.app',
  googleCloudStorageBaseUrl: 'https://storage.googleapis.com/preacherpal.ai/images/',
  recaptchaSiteKey: '',

  stripeSubscription:  {
    // grandfatherPriceId: "price_1NbY2kHtD45mMJ9rLxTg13it",
    basicPriceId: "",
    standardMonthlyPriceId: "price_1NkHu7HtD45mMJ9rHD90AcVM",
    standardYearlyPriceId: "price_1NkHwCHtD45mMJ9rbUP7iW8Q",
    premiumPriceId: "",
  },

  
  firebase: {
    apiKey: "AIzaSyDb2gTBS5tqUo_xW3sLMdVP0UHFoXnZJH4",
    authDomain: "preacher-pal-dev.firebaseapp.com",
    projectId: "preacher-pal-dev",
    storageBucket: "preacher-pal-dev.appspot.com",
    messagingSenderId: "196282873963",
    appId: "1:196282873963:web:fd9cc9e612b39c9029592e",
    measurementId: "G-JK11E4TN12"
  },

  
  
  appleAppstoreListingUrl: "",
  googlePlayListingUrl: "",
  signInRedirectUrl: '/home',
  signUpRedirectUrl: '/home',
  unauthenticatedRedirect: '/auth-flow/sign-in',
  splitScreenOptions: {
    images: {
      // signIn: 'https://storage.googleapis.com/neat-boutique-dev/images/clark-street-mercantile-qnKhZJPKFD8-unsplash.jpg',
      // signUp: 'https://storage.googleapis.com/neat-boutique-dev/images/clark-street-mercantile-qnKhZJPKFD8-unsplash.jpg',
      signIn: null,
      signUp: null,
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
