import { Account } from "../services/preacher-pal-api.service";

export class UserDisplay extends Account {
    constructor(account: Account) {
        super(account);
    }

    isFullyLoaded: boolean = false;

    
}



