import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SermonsService } from './services/sermons.service';
import { NavigationEnd, Router } from '@angular/router';
import { addIcons } from 'ionicons';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private _sermonsService: SermonsService, private _router: Router) {
    // this._router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     if(this._router.url.includes('sermon')) {
    //       this.showResearchHub = true;
    //       this._sermonsService.openResearchHub();
    //     } 
    //   }
    // });
    addIcons({
      'bible': '../assets/icons/bible.svg',
      'book': '../assets/icons/book.svg',
      'bulb': '../assets/icons/bulb.svg',
      'order': '../assets/icons/order.svg',
      'send': '../assets/icons/send.svg',
      'up': '../assets/icons/up.svg',
      'down': '../assets/icons/down.svg',
      'left': '../assets/icons/left.svg',
      'right': '../assets/icons/right.svg',
      'edit': '../assets/icons/edit.svg',
      'export': '../assets/icons/export.svg',
      'copy': '../assets/icons/copy.svg',
      'pen': '../assets/icons/pen.svg',
      'translate': '../assets/icons/translate.svg',
      'notebook': '../assets/icons/notebook.svg',
    });
    
  }
  ngOnInit(): void {
    // this._sermonsService.closeLeftSideMenu();
    // this._sermonsService.closeResearchHub();
  }
  
  
  
}
