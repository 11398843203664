/*
::::::::WARNING WARNING WARNING::::::::

        BEWARE THE MAGIC STRINGS
        ANY CHANGE TO THIS OBJECT WILL BREAK THINGS IN THE API!!!
*/

export const ChatGPTRoleTypes = {
    SYSTEM : "system",
    USER : "user",
    ASSISTANT : "assistant",
}

export const SubscriptionPlanTypes = {
    TRIAL : "trial",
    EXPIRED : "expired",
    BASIC : "basic",
    STANDARD : "standard",
    PREMIUM : "premium"
}

export const SermonTemplateTypes = {
    NONE : "None",
    FREE_FORM: "Free Form",
    EXPOSITORY : "Expository",
    TOPICAL : "Topical",
    NARRATIVE : "Narrative",
    TEXTUAL : "Textual",
    BIOGRAPHICAL : "Biographical",
    DOCTRINAL : "Doctrinal",
    ETHICAL : "Ethical or Social Justice",
    EVANGELISTIC : "Evangelistic",
    DEVOTIONAL : "Devotional",
    SEASONAL : "Seasonal or Occasional",
}

export const TagColors = {
    BLUE: "blue",
    PURPLE: "purple",
    YELLOW: "yellow",
    RED: "red",
    GREEN: "green",
    GREY: "grey",
    NONE: "none"
}