import { BibleBook, BibleChapter, BibleVersion, Sermon } from "../services/preacher-pal-api.service";
import { ChatMessageDisplay } from "./chat-message-display";

export class BibleDisplay {
    selectedVersion: BibleVersion;
    selectedBook: BibleBook;
    selectedChapter: BibleChapter;
    englishBibleVersions: BibleVersion[];
    spanishBibleVersions: BibleVersion[];
    books: BibleBook[];
    isServiceUnavailableError: boolean = false;
}