<!-- Using iframe -->
<!-- <iframe [src]="'https://storage.googleapis.com/preacherpal.us/pdfs/Galatians_%20A%20Bible%20Commentary%20for%20Teaching%20and%20Preaching%20(Interpretation_%20A%20Bible%20Commentary%20for%20Teaching%20%26%20Preaching)%20(%20PDFDrive%20).pdf'" width="100%" height="600px"></iframe> -->

<!-- Or using embed -->
<!-- <embed [src]="'https://storage.googleapis.com/preacherpal.us/pdfs/Galatians_%20A%20Bible%20Commentary%20for%20Teaching%20and%20Preaching%20(Interpretation_%20A%20Bible%20Commentary%20for%20Teaching%20%26%20Preaching)%20(%20PDFDrive%20).pdf'" width="100%" height="600px" type="application/pdf"> -->


  <!-- <pdf-viewer src="https://storage.googleapis.com/preacherpal.us/pdfs/Galatians_%20A%20Bible%20Commentary%20for%20Teaching%20and%20Preaching%20(Interpretation_%20A%20Bible%20Commentary%20for%20Teaching%20%26%20Preaching)%20(%20PDFDrive%20).pdf" 
  [render-text]="true"
  style="display: block;"></pdf-viewer> -->

  

<!-- <pdf-viewer src="https://storage.googleapis.com/preacherpal.us/pdfs/Galatians_%20A%20Bible%20Commentary%20for%20Teaching%20and%20Preaching%20(Interpretation_%20A%20Bible%20Commentary%20for%20Teaching%20%26%20Preaching)%20(%20PDFDrive%20).pdf" [original-size]="false" style="height: 500px; width: 400px;"></pdf-viewer> -->




<div class="pdf">
  <ion-row>
    <ion-col size="6">
      <ion-item class="input-item">
        <ion-select (ionChange)="changePdf($event)" interface="popover" [compareWith]="compareIdWith" [(ngModel)]="pdfsDisplay.selectedPdf" placeholder="Select a Resource">
          <ion-select-option  *ngFor="let reference of pdfsDisplay.pdfReferences" [value]="reference">{{ reference.shortName }}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
    <ion-col size="1.5">
      <ion-item class="input-item">
        <ion-select class="page-selection" *ngIf="pdfsDisplay.selectedPdf" (ionChange)="goToPage($event.detail.value)" [(ngModel)]="pdfsDisplay.selectedPdfPageNumber" interface="popover" placeholder="Select a chapter">
          <ion-select-option  *ngFor="let page of pdfsDisplay.selectedPdfPagesArray" [value]="page">{{ page }}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
    <ion-col>
      <ion-input (keyup.enter)="searchPdf()" autofocus="true" [(ngModel)]="searchText" fill="outline" shape="round" placeholder="Search">
        <ion-button (click)="searchPdf()" class="search-button" fill="clear" shape="round" slot="end" aria-label="Show/hide">
          <ion-icon slot="end" name="search-outline" aria-hidden="true"></ion-icon>
        </ion-button>
      </ion-input>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="pdfsDisplay?.selectedPdf?.sourceUrl">
    <ion-col class="pdf-col">
      <pdf-viewer class="pdf-viewer" [src]="pdfsDisplay.selectedPdf.sourceUrl"
            [original-size]="false"
            [show-all]="false"
            [fit-to-page]="true"
            [render-text]="true"
            [page]="pdfsDisplay.selectedPdfPageNumber"
            [show-borders]="false"
            (after-load-complete)="afterLoadComplete($event)">
      </pdf-viewer>
    </ion-col>
  </ion-row>
</div>


<ion-footer class="pagination ion-no-border">
  <ion-row class="ion-justify-content-center">
    <ion-col size="auto">
      <app-pagination *ngIf="pdfsDisplay?.selectedPdfTotalPages" [currentPageInput]="pdfsDisplay.selectedPdfPageNumber" [totalPages]="pdfsDisplay.selectedPdfTotalPages" (goToPage)="goToPage($event)"></app-pagination>
    </ion-col>
  </ion-row>
</ion-footer>
