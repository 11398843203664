import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthFlowPageModule } from './auth/auth-flow/auth-flow.module';
import { AuthGuard } from './auth/auth.guard';
import { SubscriptionGuard } from './guards/subscription.guard';
import { AdminGuard } from './guards/admin-only.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'prefix'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [ AuthGuard, SubscriptionGuard ]
  },
  {
    path: 'auth-flow',
    // loadChildren: () => import('./auth/auth-flow/auth-flow.module').then( m => m.AuthFlowPageModule),
    loadChildren: () => AuthFlowPageModule,
    data: { title: '' }
  },
  {
    path: 'trial-expired',
    loadChildren: () => import('./pages/trial-expired/trial-expired.module').then( m => m.TrialExpiredPageModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'subscribe-now',
    loadChildren: () => import('./pages/trial-expired/trial-expired.module').then( m => m.TrialExpiredPageModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'thanks',
    loadChildren: () => import('./pages/thanks/thanks.module').then( m => m.ThanksPageModule),
    canActivate: [ AuthGuard, SubscriptionGuard ]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then( m => m.AdminPageModule),
    canActivate: [ AuthGuard, AdminGuard ],
  },
  {
    path: 'sermon/:sermonId',
    loadChildren: () => import('./pages/sermon/sermon.module').then( m => m.SermonPageModule),
    canActivate: [ AuthGuard, SubscriptionGuard ]
  },
  {
    path: 'sermon',
    loadChildren: () => import('./pages/sermon/sermon.module').then( m => m.SermonPageModule),
    canActivate: [ AuthGuard, SubscriptionGuard ]
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
  // ,
  // {
  //   path: 'pdfs',
  //   loadChildren: () => import('./pages/pdfs/pdfs.module').then( m => m.PdfsPageModule),
  //   canActivate: [ AuthGuard, SubscriptionGuard ]
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
