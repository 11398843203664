import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
import { PdfReference } from 'src/app/services/preacher-pal-api.service';
import { SermonsService } from 'src/app/services/sermons.service';

export class PdfsDisplay {
  selectedPdf: PdfReference;
  selectedPdfTotalPages: number;
  selectedPdfPagesArray: number[];
  selectedPdfPageNumber: number = 1;
  pdfReferences: PdfReference[];
}

@Component({
  selector: 'app-pdf-reader',
  templateUrl: './pdf-reader.component.html',
  styleUrls: ['./pdf-reader.component.scss'],
})
export class PdfReaderComponent  implements OnInit {
  pdfSrc: string = "https://storage.googleapis.com/preacherpal.us/pdfs/Galatians_%20A%20Bible%20Commentary%20for%20Teaching%20and%20Preaching%20(Interpretation_%20A%20Bible%20Commentary%20for%20Teaching%20%26%20Preaching)%20(%20PDFDrive%20).pdf"; // URL of the PDF to be displayed
  searchResults: any; //SearchResult[] = null;
  pdfsDisplay: PdfsDisplay = new PdfsDisplay();
  isLoaded: boolean = false;
  searchText: string;

  
  constructor(private _sermonsService: SermonsService, private _cdr: ChangeDetectorRef) {

    this._sermonsService.pdfDisplaySubject.subscribe((pdfDisplay: PdfsDisplay) => {
      if(pdfDisplay) {
        this.pdfsDisplay = pdfDisplay;
        this.goToPage(this.pdfsDisplay.selectedPdfPageNumber);

      }
    });
    this.pdfsDisplay
  }
  ngOnInit() { }

  afterLoadComplete(pdfData: any) {
    this.pdfsDisplay.selectedPdfTotalPages = pdfData.numPages;
    this.pdfsDisplay.selectedPdfPagesArray = [...Array(this.pdfsDisplay.selectedPdfTotalPages)].map((_, i) => i + 1);
    this.isLoaded = true;
  }

  goToPage(pageNumber: number) {
    this.pdfsDisplay.selectedPdfPageNumber = pageNumber;
    this._cdr.detectChanges();
  }
  
  compareIdWith(o1, o2) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  changePdf(event) {

  }

  searchPdf() {

  }
    

}
