import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ChatGPTRoleTypes } from 'src/app/models/constants';
import { ChatGPTService } from 'src/app/services/chat-gpt.service';
import { ChatMessage } from 'src/app/services/preacher-pal-api.service';
import { Clipboard } from '@capacitor/clipboard';

@Component({
  selector: 'app-right-click-options',
  templateUrl: './right-click-options.component.html',
  styleUrls: ['./right-click-options.component.scss'],
})
export class RightClickOptionsComponent  implements OnInit {

  @Input() text: string;
  @Input() sermonId: string;
  @Input() bibleReference: string
  isSingleWord: boolean;
  constructor(private _chatGPTService: ChatGPTService, private _popoverController: PopoverController) { }

  ngOnInit() {
    this.isSingleWord = !(this.text?.split(' ').length > 1);
  }

  copyToClipboard() {
    Clipboard.write({
      string: this.text
    });
    this._popoverController.dismiss();
  }

  getBiblicalEtymology() {
    var messageContent = `What is the etymology of "${this.text}"`;
    const message = new ChatMessage({ sermonId: this.sermonId, role: ChatGPTRoleTypes.USER, content: messageContent });

    this._chatGPTService.sendMessage(message);
    this._popoverController.dismiss();
  }

  getHistoricalReference() {
    var messageContent = `What is the historical context of "${this.text}" in ${this.bibleReference}`;
    const message = new ChatMessage({ sermonId: this.sermonId, role: ChatGPTRoleTypes.USER, content: messageContent });

    this._chatGPTService.sendMessage(message);
    this._popoverController.dismiss();
  }

  getIllustration() {
    var messageContent = `Help me write an illustration concerning "${this.text}" in the context of ${this.bibleReference}`;
    const message = new ChatMessage({ sermonId: this.sermonId, role: ChatGPTRoleTypes.USER, content: messageContent });

    this._chatGPTService.sendMessage(message);
    this._popoverController.dismiss();
  }

  showSimilarVerses() {
    var messageContent = `What are some similar verses to "${this.text}" in ${this.bibleReference}`;
    const message = new ChatMessage({ sermonId: this.sermonId, role: ChatGPTRoleTypes.USER, content: messageContent });

    this._chatGPTService.sendMessage(message);
    this._popoverController.dismiss();
  }

  showTranslations() {
    // var messageContent = `Translate "${this.text}" in the original languages in which it was used in the Bible, especially in the context of ${this.bibleReference}`;
    var messageContent = `Translate "${this.text}" in the original languages in which it was used in the Bible`;
    const message = new ChatMessage({ sermonId: this.sermonId, role: ChatGPTRoleTypes.USER, content: messageContent });

    this._chatGPTService.sendMessage(message);
    this._popoverController.dismiss();
  }

}
