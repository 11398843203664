import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BibleChapter } from 'src/app/services/preacher-pal-api.service';

@Component({
  selector: 'app-bible-pagination',
  templateUrl: './bible-pagination.component.html',
  styleUrls: ['./bible-pagination.component.scss'],
})
export class BiblePaginationComponent implements OnInit {
  // @Input() totalChapters: number;
  chapters: BibleChapter[];
  @Input() set chaptersInput(value: BibleChapter[]) {
    this.chapters = value;
  }
  // @Input() chapters: BibleChapter[];
  @Input() currentChapter: BibleChapter;
  @Input() set currentChapterInput(value: BibleChapter) {
    this.currentChapter = value;
    this.loadChapterSelectors(this.currentChapter);
  }
  // @Input() totalPerChapter: number = 20;
  @Output() onChapterChange: EventEmitter<BibleChapter> =  new EventEmitter<BibleChapter>(null);

  maxChapterSelectorsToShow: number = 5;
  // totalChapters: number;
  firstChapterSelectorsToShow: BibleChapter[] = null;
  middleChapterSelectorsToShow: BibleChapter[] = null;
  lastChapterSelectorsToShow: BibleChapter[] = null;
  // currentChapter: BibleChapter;

  constructor() { }

  ngOnInit() {
    // this.totalChapters = Math.ceil(this.totalElements / this.totalPerChapter);
    if(this.chapters.length < this.maxChapterSelectorsToShow) {
      this.firstChapterSelectorsToShow = [ ...this.chapters ];
    } else {
      this.loadChapterSelectors(this.currentChapter);
    }
  }

  nextChapter() {
    
    if(+this.currentChapter.number !== this.chapters.length) {
      var chapter = this.chapters[+this.currentChapter.number];
      this.changeChapterTo(chapter);
    }
  }

  previousChapter() {
    if(+this.currentChapter.number !== 0) {
      var chapter = this.chapters[+this.currentChapter.number - 2];
      this.changeChapterTo(chapter);
    }
  }

  changeChapterTo(chapter: BibleChapter) {
    this.loadChapterSelectors(chapter);
    this.onChapterChange.emit(chapter);
  }
  
  loadChapterSelectors(chapter: BibleChapter) {
    this.currentChapter = chapter;

    if(this.chapters.length >= this.maxChapterSelectorsToShow) {
      if(+this.currentChapter.number > this.maxChapterSelectorsToShow - 2) {
        this.firstChapterSelectorsToShow = [ this.chapters[0]];
        if(+this.currentChapter.number > this.chapters.length - this.maxChapterSelectorsToShow + 2) {
          this.middleChapterSelectorsToShow = null;
          this.lastChapterSelectorsToShow =  [ ...(this.chapters.slice(this.chapters.length - this.maxChapterSelectorsToShow + 1)) ];
        } else {
          // this.middleChapterSelectorsToShow = [this.chapters[+this.currentChapter.number - 3], this.chapters[+this.currentChapter.number - 2], this.chapters[+this.currentChapter.number - 1], this.chapters[+this.currentChapter.number], this.chapters[+this.currentChapter.number + 1]];
          this.middleChapterSelectorsToShow = [ this.chapters[+this.currentChapter.number - 2], this.chapters[+this.currentChapter.number - 1], this.chapters[+this.currentChapter.number] ];
          this.lastChapterSelectorsToShow = [ this.chapters[this.chapters.length - 1] ];
        }
      } else {
        this.firstChapterSelectorsToShow = [ ...(this.chapters.slice(0, this.maxChapterSelectorsToShow - 1)) ];
        this.middleChapterSelectorsToShow = null;
        this.lastChapterSelectorsToShow = [ this.chapters[this.chapters.length - 1] ];
      }
    }
    
  }

}
