import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() totalPages: number;
  @Output() goToPage: EventEmitter<number> =  new EventEmitter<number>(null);

  maxPageSelectorsToShow: number = 5;
  firstPageSelectorsToShow: number[] = null;
  middlePageSelectorsToShow: number[] = null;
  lastPageSelectorsToShow: number[] = null;
  @Input() currentPage: number = 1;
  @Input() set currentPageInput(value: number) {
    if(value !== this.currentPage) {
      this.currentPage = value;
      this.loadPage(value);
    }
  }

  constructor() {
    
   }

  ngOnInit() {
    this.currentPage = 1;
    
    if(this.totalPages < this.maxPageSelectorsToShow) {
      this.firstPageSelectorsToShow = [ ...Array(this.totalPages).keys() ].map( i => i + 1);
    } else {
      this.firstPageSelectorsToShow = [ ...Array(this.maxPageSelectorsToShow - 1).keys() ].map( i => i + 1);
      this.lastPageSelectorsToShow = [this.totalPages];
    }
  }

  nextPage() {
    if(this.currentPage !== this.totalPages) {
      var pageNumber = this.currentPage + 1;
      this.changePageTo(pageNumber);
    }
  }

  previousPage() {
    if(this.currentPage !== 1) {
      var pageNumber = this.currentPage - 1;
      this.changePageTo(pageNumber);
    }
  }

  changePageTo(pageNumber) {
    this.loadPage(pageNumber);
    this.goToPage.emit(pageNumber);
  }

  loadPage(pageNumber: number) {
    this.currentPage = pageNumber;

    if(this.totalPages >= this.maxPageSelectorsToShow) {
      if(this.currentPage > this.maxPageSelectorsToShow - 2) {
        this.firstPageSelectorsToShow = [1];
        if(this.currentPage > this.totalPages - this.maxPageSelectorsToShow + 2) {
          this.middlePageSelectorsToShow = null;
          this.lastPageSelectorsToShow = [ ...Array(this.maxPageSelectorsToShow - 1).keys() ].map( i => this.totalPages - 3 + i);
        } else {
          this.middlePageSelectorsToShow = [this.currentPage - 1, this.currentPage, this.currentPage + 1];
          this.lastPageSelectorsToShow = [this.totalPages];
        }
      } else {
        this.firstPageSelectorsToShow = [ ...Array(this.maxPageSelectorsToShow - 1).keys() ].map( i => i + 1);
        this.middlePageSelectorsToShow = null;
        this.lastPageSelectorsToShow = [this.totalPages];
      }
    }
    
  }

}
