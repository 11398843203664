import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ChatMessage } from "../services/preacher-pal-api.service";

export class ChatMessageDisplay extends ChatMessage {
    constructor(chatMessage: ChatMessage) {
        super(chatMessage);
        
    }
   
    safeHtml: SafeHtml;
    copied: boolean;
}