import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
// import { AuthService } from '../auth/auth.service';


import { Router, UrlTree } from '@angular/router';
import { AccountDeletion, AuthService } from '../auth/auth.service';
import { User } from 'firebase/auth';
import { Account, AccountCreateRequest, AccountDeletionRequest, AccountResponse, PreacherPalApiService, Request } from './preacher-pal-api.service';
import { UserDisplay } from '../models/user-display';
import { SermonsService } from './sermons.service';
import { SubscriptionPlanTypes } from '../models/constants';
import { SermonDisplay } from '../models/sermon-display';
import { getAnalytics, logEvent, setUserProperties } from '@angular/fire/analytics';
import { getApp } from '@angular/fire/app';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  private _currentUser: UserDisplay;
  public currentUserSubject: BehaviorSubject<UserDisplay> = new BehaviorSubject<UserDisplay>(null);
  
  _firebaseApp;
  _analytics;
  
  constructor(private _preacherPalApiService: PreacherPalApiService, private _sermonsService: SermonsService) {
    // this._analytics = getAnalytics(_firebaseApp);
    this._firebaseApp = getApp(); // Get the Firebase app instance
    this._analytics = getAnalytics(this._firebaseApp); 
  }

  createAccount(googleAuthId: string, username: string, email: string) {
    const request = new AccountCreateRequest();
    request.googleAuthUserId = googleAuthId;
    request.username = username;
    request.email = email;
    const promise = new Promise((resolve, reqject) => {
      this._preacherPalApiService.createAccount(request).subscribe((response: AccountResponse) => {
        if(response.isSuccess) {
          this._setFullyLoaded(response);
        }
      });
    });
    return promise;
  }
  deleteAuthUserInit(accountDeletion: AccountDeletion) {
    const request = new AccountDeletionRequest();
    request.token = accountDeletion.token;
    request.userId = accountDeletion.userId;
    const promise = new Promise((resolve, reqject) => {
      this._preacherPalApiService.deleteAccountInit(request).subscribe();
    });
  }

  deleteAuthUserFinal(accountDeletion: AccountDeletion) {
    const request = new AccountDeletionRequest();
    request.token = accountDeletion.token;
    request.userId = accountDeletion.userId;
    const promise = new Promise((resolve, reqject) => {
      this._preacherPalApiService.deleteAccountFinal(request).subscribe();
    });
  }

  deleteAccountRollBack(accountDeletion: AccountDeletion) {
    const request = new AccountDeletionRequest();
    request.token = accountDeletion.token;
    request.userId = accountDeletion.userId;
    const promise = new Promise((resolve, reqject) => {
      this._preacherPalApiService.deleteAccountRollBack(request).subscribe();
    });
  }

  private _updateEmail() {
    const request = new Request();

    const promise = new Promise((resolve, reject) => {
      this._preacherPalApiService.updateEmail(request).subscribe((response: AccountResponse) => {
        if (response.isSuccess) {
          
          resolve(true);
        }
        else {
          // this.authService.revokeToken();
          reject(false);
        }
      });
    });
    return promise;
  }

  preloadConsumerProfile(firebaseUser: User) { 
    if(firebaseUser?.displayName) {    
      var account = new Account();
      account.name = firebaseUser.displayName
      this._currentUser = new UserDisplay(account);
      this.currentUserSubject.next(this._currentUser);
    }   
    
  }
  

  public unLoadAccounts() {
    this._currentUser = null;
    this.currentUserSubject.next(null);
  }

  loadAccount() {
    const request = new Request();
    const promise = new Promise<boolean>((resolve, reject) => {
      this._preacherPalApiService.getAccounts(request).subscribe((response: AccountResponse) => {
        if (response.isSuccess) {
          this._setFullyLoaded(response);
          
          resolve(true);
        }
        else {
          reject(false);
        }
      });
    });
    return promise;
  }

  private _setFullyLoaded (response: AccountResponse) {
    setUserProperties(this._analytics, { email: response.account.email });
    

    this._currentUser = new UserDisplay(response.account);
    this._currentUser.isFullyLoaded = true;
    this._sermonsService.setSermons(response?.sermons?.map(x => new SermonDisplay(x)));
    this._sermonsService.setEditorBlockTemplates(response?.editorBlockTemplates);
    this._sermonsService.setPdfsDisplay(response?.pdfReferences);
    this.currentUserSubject.next(this._currentUser);
  }

  isSubscriptionExpired() {
    var promise = new Promise((resolve, reject) => {
      var subscribe = this.currentUserSubject.subscribe((currentUser: UserDisplay) => {
        if(currentUser?.isFullyLoaded) {
          if(currentUser.subscriptionPlanType === SubscriptionPlanTypes.EXPIRED) {
            resolve(true);
          } else {
            resolve(false);
          }
          // subscribe.unsubscribe();
        }
      });
    });
    return promise;
  }


  private _promptUserForNameAndEmail() {

  }
  



  updateUsername(username: string) {
    // this._currentUser.consumer.name = username;
    // this._setCurrentUser(this._currentUser.consumer, this._currentUser.vendor)
    
    // this._preacherPalApiService.updateUsername(new Request());
  }


  // connectVendor(payPalSubscriptionId: string, payPalPlanId: string, googlePlace: GooglePlacesEntity, vendor: VendorProfile) {
  //   const request = new UpgradeToVendorRequest();
  //   request.vendorSubscriptionPayPalId = payPalSubscriptionId;
  //   request.googlePlace = googlePlace;
  //   request.vendorProfile = vendor;
  //   request.vendorPayPalPlanId = payPalPlanId;
  //   const promise = new Promise<boolean>((resolve, reject) => {
  //     this._neatBoutiqueApiService
  //       .upgradeToVendor(request)
  //       .subscribe((response: VendorProfileResponse) => {
  //         if (response.isSuccess) {
  //           this.setCurrentVendor(response.vendorProfile).then(() => {
  //             resolve(true);
  //           });
  //         } else if (response.errors.find((x) => x.errorCode === "409")) {
  //           reject();
  //         }
  //       });
  //     });
  //     return promise;
  // }

 

}
