<!-- <ion-row *ngIf="totalPages > 1">
  <ion-col size="auto">
    <ion-button (click)="previousPage()" [disabled]="currentPage === 1" color="light"><ion-icon name="chevron-back-outline"></ion-icon> Previous</ion-button>
  </ion-col>
  <ion-col size="auto" *ngFor="let pageSelector of firstPageSelectorsToShow">
    <ion-button (click)="changePageTo(pageSelector)" *ngIf="currentPage === pageSelector">{{ pageSelector }}</ion-button>
    <ion-button (click)="changePageTo(pageSelector)" *ngIf="currentPage !== pageSelector" color="light">{{ pageSelector }}</ion-button>
  </ion-col>
  <ion-col *ngIf="middlePageSelectorsToShow" size="auto" class="ellipses">
    ...
  </ion-col>
  <ion-col size="auto" *ngFor="let pageSelector of middlePageSelectorsToShow">
    <ion-button (click)="changePageTo(pageSelector)" *ngIf="currentPage === pageSelector">{{ pageSelector }}</ion-button>
    <ion-button (click)="changePageTo(pageSelector)" *ngIf="currentPage !== pageSelector" color="light">{{ pageSelector }}</ion-button>
  </ion-col>
  <ion-col *ngIf="lastPageSelectorsToShow" size="auto" class="ellipses">
    ...
  </ion-col>
  <ion-col size="auto" *ngFor="let pageSelector of lastPageSelectorsToShow">
    <ion-button (click)="changePageTo(pageSelector)" *ngIf="currentPage === pageSelector">{{ pageSelector }}</ion-button>
    <ion-button (click)="changePageTo(pageSelector)" *ngIf="currentPage !== pageSelector" color="light">{{ pageSelector }}</ion-button>
  </ion-col>
  <ion-col size="auto">
    <ion-button (click)="nextPage()" [disabled]="currentPage === totalPages" color="light">Next <ion-icon name="chevron-forward-outline"></ion-icon></ion-button>
  </ion-col>
</ion-row> -->

<ion-row *ngIf="totalPages > 1">
  <ion-col size="auto">
    <ion-button fill="clear" shape="round" (click)="previousPage()" [disabled]="currentPage === 1" color="light">
      <ion-icon slot="icon-only" name="left"></ion-icon>
    </ion-button>
  </ion-col>
  <ion-col size="auto" *ngFor="let pageSelector of firstPageSelectorsToShow">
    <ion-button fill="outline" shape="round" (click)="changePageTo(pageSelector)" *ngIf="currentPage === pageSelector">{{ pageSelector }}</ion-button>
    <ion-button fill="outline" shape="round" (click)="changePageTo(pageSelector)" *ngIf="currentPage !== pageSelector" color="light">{{ pageSelector }}</ion-button>
  </ion-col>
  <ion-col *ngIf="middlePageSelectorsToShow" size="auto" class="ellipses">
    ...
  </ion-col>
  <ion-col size="auto" *ngFor="let pageSelector of middlePageSelectorsToShow">
    <ion-button fill="outline" shape="round" (click)="changePageTo(pageSelector)" *ngIf="currentPage === pageSelector">{{ pageSelector }}</ion-button>
    <ion-button fill="outline" shape="round" (click)="changePageTo(pageSelector)" *ngIf="currentPage !== pageSelector" color="light">{{ pageSelector }}</ion-button>
  </ion-col>
  <ion-col *ngIf="lastPageSelectorsToShow" size="auto" class="ellipses">
    ...
  </ion-col>
  <ion-col size="auto" *ngFor="let pageSelector of lastPageSelectorsToShow">
    <ion-button fill="outline" shape="round" (click)="changePageTo(pageSelector)" *ngIf="currentPage === pageSelector">{{ pageSelector }}</ion-button>
    <ion-button fill="outline" shape="round" (click)="changePageTo(pageSelector)" *ngIf="currentPage !== pageSelector" color="light">{{ pageSelector }}</ion-button>
  </ion-col>
  <ion-col size="auto">
    <ion-button fill="clear" shape="round" (click)="nextPage()" [disabled]="currentPage === totalPages" color="light">
      <ion-icon slot="icon-only" name="right"></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>