import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { debounceTime } from 'rxjs';
import { TagColors } from 'src/app/models/constants';
import { WritersBlockDisplay } from 'src/app/models/writers-block-display';
import { WritersBlock } from 'src/app/services/preacher-pal-api.service';


@Component({
  selector: 'app-writers-block',
  templateUrl: './writers-block.component.html',
  styleUrls: ['./writers-block.component.scss'],
})
export class WritersBlockComponent  implements OnInit {

  @Input() block: WritersBlockDisplay = new WritersBlockDisplay();
  @Output() blockChange = new EventEmitter<WritersBlockDisplay>();
  // @Input() isExpanded: boolean = false;
  @Output() isExpandedChange = new EventEmitter<void>();
  @Output() onDelete = new EventEmitter<WritersBlockDisplay>();
  tagColors = TagColors;
  mainText: string;
  previousTitleText: string;
  previousBodyText: string;
  previousTagColor: string;
  showDeleteDiaglog: boolean = false

  // blockForm: FormGroup = new FormGroup({
  //   // currentEmail: new FormControl('', [ Validators.required, Validators.email ]),
  //   title: new FormControl('', [Validators.required ]),
  //   body: new FormControl('', [ ]),
  //   tagColor: new FormControl('', [Validators.required ])
  // });

  constructor() { 
    // this.blockForm.controls.title.valueChanges.pipe(
    //   debounceTime(500) // Adjust the debounce time as needed
    // ).subscribe(value => {
    //   this.block.title = value;
    //   this.blockChange.emit(this.block);
    // });

    // this.blockForm.controls.body.valueChanges.pipe(
    //   debounceTime(500) // Adjust the debounce time as needed
    // ).subscribe(value => {
    //   this.block.body = value;
    //   this.blockChange.emit(this.block);
    // });

    // this.blockForm.controls.title.valueChanges.pipe(
    //   debounceTime(500) // Adjust the debounce time as needed
    // ).subscribe(value => {
    //   this.block.title = value;
    //   this.blockChange.emit(this.block);
    // });
  }

  saveTagColor(color: string) {
    if(this.previousTagColor !== color) {
      this.block.tagColor = color;
      this.blockChange.emit(this.block);
      this.previousTagColor = color;
    }
  }

  switchToEditableEditor() {
    this.isExpandedChange.emit()
    this.block.quillInstance.focus();
  }

  onEditorCreated(quill) {
    this.block.quillInstance = quill;
  }

  saveBodyText() {
    if(this.previousBodyText !== this.block.body) {
      this.blockChange.emit(this.block);
      this.previousBodyText = this.block.body;
    }
  }

  saveTitleText() {
    if(this.previousTitleText !== this.block.title) {
      this.blockChange.emit(this.block);
      this.previousTitleText = this.block.title;
    }
  }

  delete() {
    this.onDelete.emit(this.block);
  }


  ngOnInit() {
    this.previousBodyText = this.block.body;
    this.previousTitleText = this.block.title;
    this.previousTagColor = this.block.tagColor;
  }

  


  loadQuillInstance() {

  }

}
