



<ion-header [ngClass]="{ 'hidden': !showHeader}" #palHeader class="ion-no-border">
  <ion-toolbar color="primary">
    <img class="logo" src="https://storage.googleapis.com/preacherpal.us/images/logo.svg"/>
    <ion-title class="text-center ion-align-items-center">
      Preacher Pal</ion-title>
    <ion-buttons class="hide-md-and-below" slot="end">
      <ion-buttons slot="start">
        <ion-button class="username" shape="round" *ngIf="currentUser">{{ currentUser.name }}<ion-icon name="person-circle-outline"></ion-icon></ion-button>
        <ion-button *ngIf="currentUser" fill="clear" shape="round" (click)="signOut()">
          Sign Out
        </ion-button>
        <ion-button *ngIf="!currentUser" fill="clear" shape="round" [routerLink]="['/auth-flow/sign-in']">
          Sign In
        </ion-button>
      </ion-buttons>
      
    </ion-buttons>
  </ion-toolbar>
</ion-header>

