<ion-content class="jacobi-auth-flow jacobi-auth-flow-change-email-final">
  <ion-grid>
    <ion-row class="change-email-final-outer-row outer-row">
      <ion-col
        class="content-col"
        [attr.size-sm]="splitScreenBgImage ? 12 : undefined"
        [attr.size-md]="splitScreenBgImage ? 6 : undefined"
        [attr.offset-md]="splitScreenBgImage ? 6 : undefined"
        [attr.pull-md]="splitScreenBgImage ? 3 : undefined"
        [attr.size-lg]="splitScreenBgImage ? 6 : undefined"
        [attr.offset-lg]="splitScreenBgImage ? 0 : undefined"
        [attr.pull-lg]="splitScreenBgImage ? 0 : undefined"
      >
        <ion-row>
          <ion-col class="interface-col" 
            size-sm="12"
            size-md="12"
            offset-md="2" size-md="8"
            offset-lg="4" size-lg="4">
            <ion-img class="logo-with-words" src="https://storage.googleapis.com/preacherpal.us/images/logo-with-words.png"></ion-img>

            <ion-row class="page-heading">
              <ion-col>
                <div *ngIf="!(isSuccess || isFailure)">
                  Delete Account
                  <p class="sub-heading">
                    {{ deletePromptText }}
                  </p>
                </div>
                <div *ngIf="isSuccess">Success</div>
                <div *ngIf="isFailure">Something went wrong</div>
              </ion-col>
            </ion-row>

            <ion-row *ngIf="isSuccess">
              <ion-col>
                {{ successMessage }}
              </ion-col>
            </ion-row>
            <ion-row *ngIf="isFailure">
              <ion-col>
                {{ failureMessage }}
              </ion-col>
            </ion-row>
    
            <ion-row class="auth-action-buttons">
              <ion-col>
                <ion-row>
                  <ion-col *ngIf="!(isSuccess || isFailure)" class=" delete-btn-wrapper">
                    <ion-button (click)="accountDeletion ? deleteAccountFinal() : deleteAccountInit()" part="button" [disabled]="false" class="jacobi-button auth-primary">
                      {{ deleteButtonText }}
                    </ion-button>
                  </ion-col>
                  <ion-col *ngIf="isFailure" class=" try-again-btn-wrapper">
                    <ion-button (click)="deleteAccountInit()" part="button" [disabled]="false" class="jacobi-button auth-primary">
                      Try Again
                    </ion-button>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col *ngIf="!(isSuccess || isFailure)" class=" rollback-btn-wrapper">
                    <ion-button (click)="dismiss()"  part="button" class="jacobi-button ghost-auth-secondary">
                      Cancel
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>

          </ion-col>
        </ion-row>
      </ion-col>

      <!-- split-screen -->
      <ion-col
        *ngIf="splitScreenBgImage"
        size-lg="6"
        class="hide-lg-and-below split-page-banner"
        [ngStyle]="{
          'background-image': 'url(' + splitScreenBgImage + ')',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
          'background-size': 'cover'
        }"
      >
        <div class="img-overlay"></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
