import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountsService } from '../services/accounts.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard  {
  constructor(private router: Router, private _accountService: AccountsService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const promise = new Promise<boolean | UrlTree>((resolve, reject) => {
      this._accountService.isSubscriptionExpired().then((isExpired: boolean) => {
        if(isExpired) {
          resolve(this.router.parseUrl('/trial-expired'));
        } else {
          resolve(true);
        }
      });
    });
    return promise;
  }
}
  
