import { BibleVersion, Sermon, WritersBlock } from "../services/preacher-pal-api.service";
import { ChatMessageDisplay } from "./chat-message-display";
import { TagColors } from "./constants";

export class WritersBlockDisplay extends WritersBlock {
    constructor(block: WritersBlock = null) {
        if(!block) {
            block = new WritersBlock();
            block.body = "";
            block.title = "";
        }
        super(block);
        
        if(!block?.tagColor) {
            this.tagColor = TagColors.NONE;
        }
    }

    isExpanded = false;
    quillInstance: any;
    
}